<template>
    <!-- <div class="search-box" v-show="!$store.state.loading" id="search-box"> -->
    <div class="search-box" id="search-box">
        <div class="header" v-if="$store.state.userAgent === 'Mobile'">
            <input type="text" @click="goSearchPage" v-model="$route.query.input">
            <img src="../../assets/images/search.png" alt="" width="17" height="17" class="searchImg">
            <div @click="goSearchPage">返回</div>
        </div>
        <search-result :resultList="resultList" :total="total"
            @SaveScrollTop="SaveScrollTop" @changeTaaab="changeTaaab" :searchType="searchType" :lists="lists"
            :listsEvenIndex="listsEvenIndex" :listsOddIndex="listsOddIndex" @goGoodsDesc="goGoodsDesc"/>
    </div>
</template>

<script>
import SearchResult from './SearchResult.vue'
import { queryArticleByInputVal, queryGoodsByInputVal } from '@/api/api'

export default {
  name: 'SearchPage',
  components: {
    SearchResult,
  },
  mounted(){
        document.addEventListener('scroll',this.handleScroll);
        if(this.$store.state.userAgent !== 'Mobile'){
            document.getElementById("search-box").style.cssText= 'padding-top: 58px;height: 100%';
        }
  },
  watch: {
    '$route.query.input'() {
        if(this.$store.state.userAgent !== 'Mobile'){
            this.getResult()
        }
	}
  },
  destroyed() {
        document.removeEventListener('scroll', this.handleScroll);
  },
  data:()=>{
    return {
        resultList: [],
        limit: 5,
        total: 0,
        isEnd: false,
        scroll: 0,
        prevInputVal: '',
        fromPath:'',
        searchType: 'article',
        lists:[],
        listsEvenIndex:[],
        listsOddIndex:[],
    }
  },
  methods:{
        getResult(type){
            if(this.limit <= 10){
                this.$store.commit('Set_Loading_State', true)
            }
            if(type){
                this.searchType = type
            } else {
                this.searchType = this.$route.query.type ? this.$route.query.type : 'article'
            }
            if(this.searchType === 'article'){
                queryArticleByInputVal({ limit: this.limit, inputVal: this.$route.query.input }).then((res)=>{
                    let tempArr = []
                    this.total = res.data.total //数据库中返回的所有文章总数
                    res.data.data.forEach((item)=>{
                    let temp = {}
                    temp.tid = item.tid
                    temp.title = item.title
                    temp.context = item.context
                    // 图片显示前三张
                    let imgUrlArr = item.imgUrl ? item.imgUrl.split(',') : []
                    temp.articleImg = {}
                    if(imgUrlArr.length === 1 || imgUrlArr.length === 2){
                        temp.articleImg.img_right = imgUrlArr[0]
                        temp.articleImg.total = 1 // 显示1张
                    } else if(imgUrlArr.length >= 3){
                        temp.articleImg.img_left = imgUrlArr[0]
                        temp.articleImg.img_right = imgUrlArr[2]
                        temp.articleImg.img_center = imgUrlArr[1]
                        temp.articleImg.total = 3 // 显示3张
                    } else {
                        temp.articleImg.total = 0 // 不显示图片
                    }
                        tempArr.push(temp)
                    })
                    this.resultList = tempArr
                    setTimeout(()=>{
                        this.$store.commit('Set_Loading_State', false)
                        this.prevInputVal = this.$route.query.input
                    },0)
                })
            } else if(this.searchType === 'goods'){
                queryGoodsByInputVal({ limit: 100, inputVal: this.$route.query.input }).then(res=>{
                    this.lists.length = 0
                    this.listsEvenIndex.length = 0
                    this.listsOddIndex.length = 0
                    this.total = res.data.total //数据库中返回的所有文章总数
                    res.data.data.data.forEach((item)=>{
                        let temp = {}
                        temp.gid = item.gid
                        temp.imgUrl = item.mini_main_list_img_url
                        temp.title = item.title
                        temp.price = item.price
                        temp.amount = item.amount
                        this.lists.push(temp)
                    })
                    for(let i = 0; i < this.lists.length; i++){
                        if(i % 2 === 0){
                            this.listsEvenIndex.push(this.lists[i])
                        } else if(i % 2 === 1){
                            this.listsOddIndex.push(this.lists[i])
                        }
                    }
                    setTimeout(()=>{
                        this.$store.commit('Set_Loading_State', false)
                        this.prevInputVal = this.$route.query.input
                    },0)
                })
            }
        },
        changeTaaab(id){
            let searchType
            if(String(id) === '0'){
                searchType = 'article'
            } else if(String(id) === '1'){
                searchType = 'goods'
            }
            this.getResult(searchType)
        },
        goGoodsDesc(gid){
            this.$router.push({ path:'/shop-desc',  query:{ gid: gid }})
        },
        // 无限滚动加载页面
        handleScroll() {
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight // 可视区的高度
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 滚动条距离顶部的距离
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight //dom元素的高度，包含溢出不可见的内容
            //vue中获取滚动条到底部的距离
            let scrollBottom = scrollHeight - scrollTop - windowHeight
            //以下三个条件不执行数据加载：1.数据正在加载的状态，2.已经到底了，3.滚动条距离底部的距离小于100px
            if (!this.$store.state.loading && !this.isEnd && scrollBottom < 60 && this.resultList.length < this.total) {
                this.limit += 10
                this.isEnd = true
                this.getResult(this.searchType)
            } else {
                this.isEnd = false
            }
        },
        goSearchPage(){
            // this.$router.back()在夸克浏览器上有bug，故用指定路由
            if(this.$route.query.type && this.$route.query.type === 'goods'){
                this.$router.push({ path:'/search', query:{ input: this.$route.query.input, type: 'goods' } })
            } else {
                this.$router.push({ path:'/search', query:{ input: this.$route.query.input } })
            }
            this.total = 0
            this.limit = 10
            this.resultList = []
        },
        // 跳转前记住滚动条位置
        SaveScrollTop(){
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop // 滚动条距离顶部的距离
        },
        // 存fromPath
        setFromPath(from){
            this.fromPath = from
        }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.setFromPath(from.path))
  },
  activated(){
    // 文章详情页过来不请求，搜索页过来请求
    if(this.fromPath !== '/article'){
        this.getResult()
    }
    // 从文章详情返回首页，滚动到上次位置
    // 自动获取焦点
    setTimeout(() => {
      document.documentElement.scrollTop = this.scroll
      document.body.scrollTop = this.scroll
	}, 0)
  }
}
</script>

<style scoped lang="scss">
.search-box {
    position: relative;
    height: 100vh;
    .header {
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        right: 0;
        background: #f3f3f3;
        input {
            width: 100%;
            border-radius: 20px;
            height: 40px;
            border: 1px solid #eee;
            margin: 10px 5px 0 10px;
            padding-left: 40px;
            box-sizing: border-box;
            -webkit-appearance: none;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            margin-top: 10px;
            width: 40px;
            height: 40px;
            min-width: 48px;
            padding-right: 10px;
        }
        .searchImg {
            position: absolute;
            left: 25px;
            top: 21px;
        }
    }
}

</style>