<template>
  <div class="search-result-box">
    <tabs @changeTaaab="changeTaaab" :tabs="['文案','商品']" :equalDivider="false" :bgColor="'#f3f3f3'" :parent="true"/>
    <div v-if="resultList.length > 0 && tabId === '0' || lists.length > 0 && tabId === '1'">
      <div v-if="searchType === 'article'">
        <Card :lists="resultList" @SaveScrollTop="SaveScrollTop" />
        <div class="load-more"  v-if="resultList.length !== total || !resultList.length">正在加载中...</div>
        <div class="load-more" v-else>已经到底部啦！</div>
      </div>
      <div v-else>
        <goods-card :listsEvenIndex="listsEvenIndex" :listsOddIndex="listsOddIndex" @goGoodsDesc="goGoodsDesc"/>
        <div class="load-more"  v-if="lists.length !== total || !lists.length">正在加载中...</div>
        <div class="load-more" v-else>已经到底部啦！</div>
      </div>
    </div>
    <div class="none-list" v-else>
        <img src="../../assets/images/none.svg" alt="">
        <div class="none">没有更多内容</div>
    </div>
  </div>
</template>

<script>
import GoodsCard from '../../components/card/GoodsCard.vue'
import Card from '../../components/card/index.vue'
import Tabs from '../../components/tabs/index.vue'

export default {
  name: 'SearchResult',
  components: {
    Card,
    Tabs,
    GoodsCard
  },
  mounted(){
  },
  activated(){
    if(this.$route.query.type === 'goods'){
      this.tabId = '1'
    } else {
      this.tabId = '0'
    }
  },
  props:{
    resultList:{
      type: Array,
      default: ()=>{}
    },
    total:{
      type: Number,
      default: 0
    },
    searchType:{
      type: String,
      default: 'article'
    },
    lists:{
      type: Array,
      default: ()=>[]
    },
    listsEvenIndex:{
      type: Array,
      default: ()=>[]
    },
    listsOddIndex:{
      type: Array,
      default: ()=>[]
    }
  },
  data:()=>{
    return {
      tabId: '0'
    }
  },
  methods:{
    SaveScrollTop(){
      this.$emit('SaveScrollTop')
    },
    changeTaaab(id){
      this.tabId = String(id)
      this.$emit('changeTaaab', id)
    },
    goGoodsDesc(gid){
      this.$emit('goGoodsDesc', gid)
    }
  }
}
</script>

<style scoped lang="scss">
.search-result-box {
    padding-top: 95px;
    .load-more {
        text-align: center;
        color: #969699;
        padding: 0px 0 10px 0;
        font-size: 14px;
    }
}
.none-list {
    margin: 90px auto;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    max-width: 625px;
    .none {
        margin: 10px 0 0;
        color: #969699;
    }
}
</style>